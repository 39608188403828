import React from 'react'


export const Footer = () => {
  return (
    <footer className='bg-slate-900 text-slate-100 text-center bottom-0 left-0 w-full p-6 mx-auto'>
        <div className='container flex justify-between items-center'> 
        <div className='flex items-start flex-col gap-2'>
       
       
       <div>    </div>
        </div>
        <div className='flex items-end flex-col gap-1'>
       
       
        </div>
        
        </div>
          
    </footer>
    
  )
}
