import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';
import {APP_ROUTES } from '../utils/constants';

export default function NotFound() {
  const navigate = useNavigate();
    return (
        <div>
          <Result
    status="404"
    title="404"
    subTitle="Sorry, the page you visited does not exist."
    extra={<Button type="primary" onClick={()=>{navigate(APP_ROUTES.SIGN_IN)}}>Back Home</Button>}
  />
        </div>
    )
}
