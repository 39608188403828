import React from 'react'
import logo from "../../../img/logo.png";

export const Footer = () => {
  return (
    <div className='flex justify-center items-center flex-col text-sm text-slate-600 mt-8 p-8 bg-gradient-to-t from-slate-300'>
      <div className='container flex justify-center items-center flex-col gap-2'>
      <a href='/'><img width={40} height={40} src={logo} /></a> <span>Paylaş Bunu Uygulaması © 2024 </span></div>
  </div>
  )
}
