import React from 'react';
import axios from 'axios';
import { useState } from 'react';
import { API_ROUTES, APP_ROUTES, APP_PARAMS } from '../utils/constants';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '../lib/customHooks';
import { Typography } from 'antd';
import logo from '../img/logo.png';
import { storeTokenInLocalStorage } from '../lib/common';
import { Button, Checkbox, Form, Input, message} from 'antd';


const SignIn = () => {
  const navigate = useNavigate();
  const { user, authenticated } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  if (user || authenticated) {
    navigate(APP_ROUTES.CATEGORY)
  }

  const { Text } = Typography;
  const [form] = Form.useForm();
 
  // To disable submit button at the beginning.
  
  const onFinish = (values) => {
    signIn(values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const signIn = async (values) => {
    try {
      
      setIsLoading(true);
      const response = await axios({
        method: 'post',
        url: API_ROUTES.SIGN_IN,
        headers: {
          'mobilekey': APP_PARAMS.MOBILE_KEY,
          'Content-Type': 'application/json'
        },
        data: {
          username:values.username,
          password:values.password
        }
      });
      storeTokenInLocalStorage(response.data.accessToken);
      navigate(APP_ROUTES.CATEGORY)
    }
    catch (err) {
      if(err.response)
        message.error(err.response.data.message);
      else 
      message.error(err.message);
    }
    finally {
      setIsLoading(false);
    }
  };


  return (
    // SIGN IN FORM TEMPLATE
   <>
   
   <div className='vh-100 flex h-screen flex-col md:flex-row'>
      <div className='flex flex-col justify-between lg:w-1/3 bg-plane-enter m-4 p-6'>
       
      </div>

      <div className='flex flex-col flex-auto justify-center inset-8 p-6'>
        <div className='text-3xl text-slate-700 font-bold mb-6'>Giriş Yap!</div>

        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 600,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="E-Posta"
            name="username"
            rules={[
              {
                required: true,
                message: 'Please input your username!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Parola"
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="remember"
            valuePropName="checked"
            wrapperCol={{
              sm: {
                span: 18,
                offset: 8
              },
              span: 16,
              offset: 0,

            }}
          >
            <Checkbox>Beni Unutma</Checkbox>
          </Form.Item>

          <Form.Item
            wrapperCol={{
              sm: {
                span: 18,
                offset: 8
              },
              span: 16,
              offset: 0,

            }}
          >
            <Button className="w-full font-bold" type="primary" htmlType="submit">
              Giriş
            </Button>
          </Form.Item>
        </Form>

        
      </div>
    </div>
  </>
  );
}


export default SignIn;