import React, { useState, useEffect } from 'react';

import { Button, Table, Space, Popconfirm,Switch , message } from 'antd';
import axios from 'axios';
import { API_ROUTES } from '../../utils/constants';
import { getTokenFromLocalStorage } from '../../lib/common';


const Users = () => {
 
  const [users, setUsers] = useState([]);
  
  

  
 
  const renderBlock = (value,record) => {
    return <Switch checked={!value}  onClick={(checked)=>{update({id:record._id,blocked:false, isNew:!checked})}}/>  
  }
  const columns = [
    {
      title: 'İsim',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Soyisim',
      dataIndex: 'lastname',
      key: 'lastname'
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Aktif',
      dataIndex: 'isNew',
      key: 'isNew',
      render: (value,record) => renderBlock(value,record)
    },
    {
      title: 'Oluşturulma Zamanı',
      dataIndex: 'createdAt',
      key: 'createdAt'
    },   
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space>
          <Popconfirm title="Silmek istediğinize emin misiniz?" onConfirm={() => handleDelete(record._id)}>
            <Button type="primary" danger>Sil</Button>
          </Popconfirm>
        </Space>

      ),
    },
  ];

  const getUsers = async () => {
    try {
      const response = await axios({
        method: 'get',
        url: API_ROUTES.GET_USERS,
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`
        }
      });
      setUsers(response.data)

    }
    catch (err) {
      if(err.response)
      message.error(err.response.data.message);
     else 
       message.error(err.message);

    }
    finally {

    }
  }

  const handleDelete = async (id) => {
    try {
      await axios({
        method: 'DELETE',
        url: API_ROUTES.DELETE_USER + '/' + id,
        headers: {
          'Authorization': `Bearer ${getTokenFromLocalStorage()}`,
        }
      });

    }
    catch (err) {
      if(err.response)
      message.error(err.response.data.message);
     else 
       message.error(err.message);

    }
    finally {
      getUsers();
    }
  }
  const update = async (data) => {
    try {
      await axios({
        method: 'POST',
        url: API_ROUTES.UPDATE_USER,
        data:data,
        headers: {
          'Authorization': `Bearer ${getTokenFromLocalStorage()}`,
        }
      });

    }
    catch (err) {
      if(err.response)
      message.error(err.response.data.message);
     else 
       message.error(err.message);

    }
    finally {
      getUsers();
    }
  }
  useEffect(() => {
   getUsers()
  }, [])

  return (
    <>
      <div className='container mx-auto'>
      <div style={{ margin: "-5px 0px" }} className='flex text-lg font-bold rounded-t-3xl backdrop-blur-xl bg-white/60 justify-between p-6  flex-col lg:flex-row'>
          <div className='py-4 lg:py-0'> Kullanıcı Listesi</div>
        
        </div>
        <div className='h-screen shadow-md bg-white rounded-lg  p-8'>
          <Space
            style={{
              marginBottom: 16,
            }}
          >
            
          </Space>
          <Table rowKey={(record) => record._id} columns={columns} dataSource={users}  />
        </div>
       
       
      </div>
    </>
  )
}

export default Users;