import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_ROUTES, APP_ROUTES } from '../utils/constants';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Alert, Space } from 'antd';

export default function Confirm() {
    const [isLoad, setIsLoad] = useState(false);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get("token")
    const navigate = useNavigate();

    const confirm = async () => {
        console.log("geldi.");
        try {

            const response = await axios({
                method: 'POST',
                url: API_ROUTES.CONFIRM,
                data: {
                    jwtCode: token,

                }
            });          
            if(response.data.id){
                setMessage("Kullanıcı doğrulaması gerçekleştirildi. Lütfen sistem yöneticisinden hesabın aktifleştirilmesini talep ediniz.");
                setTimeout(() => {
                    navigate(APP_ROUTES.SIGN_IN);
                }, 10000)
            }
           
            // navigate(APP_ROUTES.SIGN_IN);
        }
        catch (err) {

            setError(true);
            setMessage(err.message);
            setTimeout(() => {
                navigate(APP_ROUTES.SIGN_IN);
            }, 10000)
        }
        finally {
            setIsLoad(true);

        }
    };

    useEffect(() => {
            confirm();
    },[]);

    if (error) {
        return (
            <>
                <Space direction="vertical" style={{ width: '100%' }}>
                    <Alert message="Error" type="error" showIcon description={message} />
                </Space>
            </>
        )
    }

    return (
        <>
        <Space direction="vertical" style={{ width: '100%' }}>
            <Alert message="Success Tips" type="success" showIcon description={message} />
        </Space>
    </>
    )
}
