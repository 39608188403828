import React from "react";
import { Card, Col, Row, Statistic } from "antd";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { AndroidOutlined, AppleOutlined } from '@ant-design/icons';
import { Radio, Tabs } from 'antd';

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
  labels: ["Yolcu", "Kargo"],
  datasets: [
    {
      label: "Adet:  ",
      data: [604, 34],
      backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)"],
      borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
      borderWidth: 1,
    },
    
  ],
  
};
export const filodata = {
  labels: ['A320', 'A330/350', 'B737', 'B777/787'],
  
  datasets: [
    {
      label: 'Adet:',
      data: [242, 66, 241, 55],
      backgroundColor: [
       
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ],
      borderColor: [
       
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

export const Content = () => {
  return (<>
      <div className="flex flex-col w-full items-center align-center h-full relative">
        <div className="container  -mt-32 h-full">
          <div className="h-72 p-6 justify-between flex items-center gap-16 ">
           <div>
          
            <Row gutter={16}>
              <Col span={120}>
                <Statistic title="İşletme Sayısı" value={13} />
                <Statistic title="Hava Aracı Sayısı" value={36} />
                </Col><Col span={120}>
                <Statistic title="Koltuk Kapasitesi" value={2356} />
                <Statistic title="Kargo Kapasitesi" value={2620324} />
              </Col>
            </Row>
            </div>
           <div className="flex align-end items-end gap-6 flex-col my-4">
       
            <Radio.Group defaultValue="a" size="medium">
      <Radio.Button value="a">Yolcu</Radio.Button>
      <Radio.Button value="b">Kargo</Radio.Button>

    </Radio.Group>
        
            <div className="flex h-56"> 
            <div className="flex flex-col gap-3"><label className="text-sm font-semibold text-slate-600">FILO</label>
            <Doughnut data={filodata} /></div>
            <div className="flex flex-col gap-3"><label className="text-sm font-semibold text-slate-600">FILO</label>
            <Doughnut data={data} /></div>
            <div className="flex flex-col gap-3"><label className="text-sm font-semibold text-slate-600">FILO</label>
            <Doughnut data={data} /></div>
            <div className="flex flex-col gap-3"><label className="text-sm font-semibold text-slate-600">FILO</label>
            <Doughnut data={data} /></div></div></div>
          </div>
          <div className="border rounded-2xl bg-white my-16 border-slate-900 h-full p-6">


          <Tabs
    defaultActiveKey="1"
    items={[
      {
        label: 'THY',
        key: '1',
        children: 'Tab 1',
      },
      {
        label: 'SUNEXPRESS',
        key: '2',
        children: 'Tab 2',
      },
      {
        label: 'PEGASUS',
        key: '3',
        children: 'Tab 3',
      },
      {
        label: 'MNG',
        key: '4',
        children: 'Tab 4',
      },
    ]}
  />
            
      
          </div>
        </div>
      </div>
    </>
  );
};
