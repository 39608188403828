import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Form, Input, Popconfirm, Table, message } from 'antd';
import { API_ROUTES } from '../../../utils/constants';
import axios from 'axios';
import { DeleteOutlined} from '@ant-design/icons';
import { getTokenFromLocalStorage } from '../../../lib/common';
const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

export default function Manufacturer() {
  const [dataSource, setDataSource] = useState([]);
  
  const [count, setCount] = useState(1);

  const defaultColumns = [
   {
      title: 'Üreticisi',
      dataIndex: 'name',
      
      editable: true,
    },
    {
      title: 'Tipi',
      dataIndex: 'model',
     
      editable: true,
    },
    {
      title: 'Modeli',
      dataIndex: 'type',
     
      editable: true,
    },
    {
      title: 'Yolcu/Kargo',
      dataIndex: 'isCargo',
     
      editable: true
    },
   
    {
      title: 'İşlem',
      dataIndex: 'operation',
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <Popconfirm title="Silmek istediğinize emin misiniz?" onConfirm={() => handleDelete(record._id)}>
             <a><DeleteOutlined style={{color:'red'}}/></a>
          </Popconfirm>
        ) : null,
    },
  ];
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  const handleAdd = () => {
    const newData = {
      key: count,
      name: `TYPE ${count}`,
      type:`TYPE ${count}`,
      model:`TYPE ${count}`,
      isCargo:'Yolcu'
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };
  const handleDelete = async(key) => {
   
    try {
      await axios({
        method: 'DELETE',
        url: API_ROUTES.DELETE_MANUFACTURER+'/'+key,
        headers: { 
          'Authorization': `Bearer ${getTokenFromLocalStorage()}`,          
        }        
      });
     
    }
    catch (err) {
      if(err.response)
      message.error(err.response.data.message);
     else 
       message.error(err.message);
      
    }
    finally {
      getManufatures();
    }
  };
  const handleSave = async (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    
    const data = row._id ? {'name':row.name,'type':row.type,'model':row.model,'isCargo':row.isCargo=='Yolcu'? false:true,'id':row._id} : {'name':row.name,'type':row.type,'model':row.model,'isCargo':row.isCargo=='Yolcu'? false:true}
   if(data.name && data.model && data.type){
    try {
      await axios({
        method: 'POST',
        url: API_ROUTES.ADD_MANUFACTURER,
        headers: { 
          'Authorization': `Bearer ${getTokenFromLocalStorage()}`,          
        },
        data:data
      });
     
    }
    catch (err) {
      if(err.response)
      message.error(err.response.data.message);
     else 
       message.error(err.message);
      
    }
    finally {
      getManufatures();
    }
  }
   
  };


  useEffect(() => {
    getManufatures();
  },[]);
  const getManufatures = async () =>{
   
    try {
      const response = await axios({
        method: 'get',
        url: API_ROUTES.GET_MANUFACTURER,
        headers: { 
          Authorization: `Bearer ${getTokenFromLocalStorage()}`  
        }
      });    
      let newArr=[]
      response.data.forEach(element => {
        element.isCargo = element.isCargo?'Kargo':'Yolcu';
        newArr.push(element);
      });
      newArr.sort((a, b) => {
        // Only sort on name if not identical
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        // Sort on model
        if (a.model < b.model) return -1;
        if (a.model > b.model) return 1;
        // Sort on type
        if (a.type < b.type) return -1;
        if (a.type > b.type) return 1;
        return 0;
      });
      
      setDataSource(newArr);
      
    }
    catch (err) {
      if(err.response)
      message.error(err.response.data.message);
     else 
       message.error(err.message);
      
    }
    finally {
      
    }
  };
  
  return (
    <div>
        <div className='text-xs py-3 text-slate-500'>Düzenlemek için tip ismine tıkla.</div>
      <Table
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={dataSource}
        columns={columns}
      />
      <Button
        onClick={handleAdd}
        type="primary"
        className='my-4 w-full font-bold'
      >
        Yeni Ekle
      </Button>
      
    </div>
  );
}
