import React, { useEffect, useState } from 'react';

import { message, Table, Popconfirm, Modal, Button, Input, Form, Space, Upload,Image } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { API_ROUTES, APP_PARAMS, APP_ROUTES } from '../../utils/constants';
import axios from 'axios';
import { useUser } from '../../lib/customHooks';
import { getTokenFromLocalStorage } from '../../lib/common';
import { Row, Col } from 'antd/es';
import { useNavigate } from "react-router-dom";
import FormItem from 'antd/es/form/FormItem';
const { TextArea } = Input;

const Category = () => {
  const { user, authenticated } = useUser()
  const [category, setCategory] = useState([])
  const [isCategoryTypeModalOpen, setIsCategoryTypeModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const getCategory = async () => {
    try {
      const response = await axios({
        method: 'get',
        url: API_ROUTES.GET_CATEGORY,
        headers: {
          'authkey': APP_PARAMS.ADMIN_KEY,
          Authorization: `Bearer ${getTokenFromLocalStorage()}`
        }
      });

      setCategory(response.data);
      if (selectedCategory == undefined && response.data.length > 0) {
        setSelectedCategory(response.data[0]._id);
      }
    }
    catch (err) {
      if (err.response)
        message.error(err.response.data.message);
      else
        message.error(err.message);

    }
    finally {

    }
  }


  const handleDelete = async (id) => {
    try {
      const response = await axios({
        method: 'Delete',
        url: API_ROUTES.GET_CATEGORY + "/" + id,
        headers: {
          'authkey': APP_PARAMS.ADMIN_KEY,
          'Authorization': `Bearer ${getTokenFromLocalStorage()}`,
          'Content-Type': 'application/json',
        },

      });

      getCategory()
    }
    catch (err) {
      if (err.response)
        message.error(err.response.data.message);
      else
        message.error(err.message);

    }
    finally {

    }
  }

  const onFinishCategory = async (values) => {
    console.log(values);
    try {
      const response = await axios({
        method: 'post',
        url: API_ROUTES.GET_CATEGORY,
        headers: {
          'authkey': APP_PARAMS.ADMIN_KEY,
          'Authorization': `Bearer ${getTokenFromLocalStorage()}`,
          //'Content-Type': 'application/json',
          "Content-Type": "multipart/form-data",
        },
        data: {
          "name": values.name,
          "file": values.file[0].originFileObj,
          "priority": parseInt(values.priority)

        }
      });

      getCategory()
    }
    catch (err) {
      if (err.response)
        message.error(err.response.data.message);
      else
        message.error(err.message);

    }
    finally {

    }
  };




  const renderCategory = () => {
    return (
      <>
        <Form

          layout="vertical"
          onFinish={onFinishCategory}
          autoComplete="off"
        >
          <Form.Item
            name="name"
            label="Kategori İsmi"
            rules={[
              {
                required: true,
              }
            ]}
          >
            <Input placeholder="Kategori giriniz" />
          </Form.Item>
          <FormItem label="Kategori Icon" valuePropName="fileList" getValueFromEvent={normFile} name="file">
            <Upload listType="picture-card" maxCount={1}>
              <button
                style={{
                  border: 0,
                  background: 'none',
                }}
                type="button"
              >
                <PlusOutlined />
                <div
                  style={{
                    marginTop: 8,
                  }}
                >
                  Upload
                </div>
              </button>
            </Upload>
          </FormItem>
          <Form.Item
            name="priority"
            label="Sıra Numarası"
            rules={[
              {
                required: true,
              }
            ]}
          >
            <Input placeholder="Sıralama" />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                Kaydet
              </Button>

            </Space>
          </Form.Item>
        </Form>
        <Table columns={columns} dataSource={category} />
      </>

    )
  }

  useEffect(() => {
    if (authenticated) {
      getCategory();

    }
  }, [authenticated])

  const columns = [
    {
      title: 'Kategori',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Sıralama',
      dataIndex: 'priority',
      key: 'priority',
    },
    {
      title: 'Sil',
      dataIndex: 'operation',
      render: (_, record) =>
        category.length >= 1 ? (
          <Popconfirm title="Silmek istediğinize emin misiniz?" onConfirm={() => handleDelete(record._id)}>
            <a>Delete</a>
          </Popconfirm>
        ) : null,
    },
  ]

  const columnsMain = [
    {
      title: 'Kategori',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Kategori Görseli',
      dataIndex: 'image',
      key: 'image',
      render: (_, record) => <Image
      width={36}
      src={API_ROUTES.API_IMAGE+"/"+ record.image}
    />
    },
    {
      title: 'Sıralama',
      dataIndex: 'priority',
      key: 'priority',
    },
    {
      title: 'Alt Kategori Ekle',
      dataIndex: 'operation',
      render: (_, record) =>
        category.length >= 1 ? (
          <a onClick={() => navigate(APP_ROUTES.SUB_CATEGORY + "/" + record._id,{state:{categoryName:record.name}})}>Alt Kategori Ekle  </a>
        ) : null,
    },
    {
      title: 'Sil',
      dataIndex: 'operation',
      render: (_, record) =>
        category.length >= 1 ? (
          <Popconfirm title="Silmek istediğinize emin misiniz?" onConfirm={() => handleDelete(record._id)}>
            <a>Delete</a>
          </Popconfirm>
        ) : null,
    },
  ]



  return (
    <>
      <div style={{ margin: "-5px 0px" }} className='flex text-lg font-bold rounded-t-3xl backdrop-blur-xl bg-white/60 justify-between p-6  flex-col lg:flex-row'>
        <div className='py-4 lg:py-0'></div>
        <div className='flex gap-1'>
          <Button onClick={() => setIsCategoryTypeModalOpen(true)}>Kategori Ekle</Button>
        </div>

      </div>
      <Row>
        <Col span={8} offset={4}>
          <Table columns={columnsMain} dataSource={category} style={{ "marginTop": "50px" }} />
        </Col>
      </Row>
      {isCategoryTypeModalOpen &&
        <Modal title="Kategoriler" open={isCategoryTypeModalOpen} onOk={() => setIsCategoryTypeModalOpen(false)} onCancel={() => setIsCategoryTypeModalOpen(false)} destroyOnClose={true}>
          {renderCategory()}
        </Modal>
      }
    </>
  )
}

export default Category;