import React from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { API_ROUTES, APP_ROUTES,COMMON_MESSAGE } from '../utils/constants';
import { Link, useNavigate } from 'react-router-dom';
import { Space, Typography } from 'antd';

import { Row, Button, Checkbox, Form, Input, Select, message} from 'antd';


const SignUp = () => {
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false);

  const signUp = async (values) => {
    try {
      setIsLoading(true);
      const response = await axios({
        method: 'POST',
        url: API_ROUTES.OTP,
        data: {
          name: values.name,
          lastname: values.lastname,
          password: values.password,
          email: values.email
        }
      });
      if (response.status==201) {
        
        message.success(COMMON_MESSAGE.REGISTER_SUCCESS);
      }
      //navigate(APP_ROUTES.SIGN_IN);
    }
    catch (err) {
      if(err.response)
        message.error(err.response.data.message);
      else 
      message.error(err.message);
     
    }
    finally {
      setIsLoading(false);
    }
  };
  const onFinish = (values) => {
    signUp(values);
  };
  
  const { Option } = Select;
  const { Text } = Typography;
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 16,
      },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };
  const [form] = Form.useForm();

  
  return (
    // SIGN UP FORM TEMPLATE
    <>
    
    <div className='vh-100 flex h-screen flex-col md:flex-row'>
      <div className='flex flex-col justify-between lg:w-1/3 bg-plane m-4 p-6'>
      

      </div>

      <div className='flex flex-col flex-auto justify-center inset-8 p-6'>
        <div className='text-3xl text-slate-700 font-bold mb-6'>Kayıt Ol!</div>
        <Form
          className='mt-6 w-100'
          {...formItemLayout}
          form={form}
          name="register"
          onFinish={onFinish}
          initialValues={{
            residence: ['zhejiang', 'hangzhou', 'xihu'],
            prefix: '86',
          }}
          style={{
            maxWidth: 600,
          }}
          scrollToFirstError
        >
          <Form.Item
            name="name"
            label="İsim"
            rules={[
              {
                type: 'input',
                message: 'The input is not valid Name!',
              },
              {
                required: true,
                message: 'Lütfen isim alanını doldurunuz!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="lastname"
            label="Soyisim"
            rules={[
              {
                type: 'input',
                message: 'The input is not valid SurName!',
              },
              {
                required: true,
                message: 'Lütfen soyisim alanını doldurunuz!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: 'email',
                message: 'Lütfen geçerli bir e-mail giriniz!',
              },
              {
                required: true,
                message: 'Lütfen E-mail alanını doldurunuz!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="password"
            label="Parola"
            rules={[
              {
                required: true,
                message: 'Lütfen parola alanını doldurunuz!',
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="confirm"
            label="Tekrar Parola"
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Lütfen parola alanını doldurunuz!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Girmiş olduğunuz paralo eşleşmedi!'));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="gender"
            label="Cinsiyet "
            rules={[
              {
                required: true,
                message: 'Lütfen cinsiyet seçiniz!',
              },
            ]}
          >
            <Select placeholder="Cinsiyet seçiniz.">
              <Option value="male">Erkek</Option>
              <Option value="female">Kadın</Option>
              <Option value="other">Diğer</Option>
            </Select>
          </Form.Item>



          <Form.Item
            name="agreement"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value ? Promise.resolve() : Promise.reject(new Error('Kullanım sözleşmesini onaylamanız gerekmektedir.')),
              },
            ]}
            {...tailFormItemLayout}
          >
         
            <Checkbox>
              Kullanım <a href="">koşullarını</a> kabul ediyorum.
            </Checkbox>
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Button className="w-full font-bold" type="primary" htmlType="submit" loading={isLoading}>
              Kayıt Ol
            </Button>
          </Form.Item>
        </Form>
        <Text strong>Hesabın var ise <Link to={APP_ROUTES.SIGN_IN}>Oturum Aç</Link> </Text>
      </div>
    </div>
  </>
  );
}

export default SignUp;