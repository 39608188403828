import React from "react";
import { Header } from "../components/showpage/Header";
import { Content } from "../components/showpage/Content";
import { Footer } from "../components/showpage/Footer";
export default function ShowPage() {
  return (

<div className="flex flex-col bg-showpage min-h-full">
      <Header/>
      <Content />
      <Footer />
    </div>

)
 
}
