import React, { useEffect, useState } from 'react';
import { useParams,useNavigate, useLocation } from "react-router-dom";
import { message, Table, Popconfirm, Modal, Button, Input, Form, Space, Image,Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { API_ROUTES, APP_PARAMS } from '../../utils/constants';
import axios from 'axios';
import { useUser } from '../../lib/customHooks';
import { getTokenFromLocalStorage } from '../../lib/common';
import { Row, Col } from 'antd/es';
import { render } from '@testing-library/react';

const AddMedia = () => {
  const { user, authenticated } = useUser();
  const [isSubCategoryTypeModalOpen, setIsSubCategoryTypeModalOpen] = useState(false);
  const [subCategoryMedia, setSubCategoryMedia] = useState([])
  const [subCategoryName,setSubCategoryName] = useState();
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  
  useEffect(() => {
    if (authenticated) {
      setSubCategoryName(location.state.subCategoryName);
       getSubCategoryMedia(id);
    }
  }, [authenticated])

  const getSubCategoryMedia = async (subCategoryId) => {
   
    try {
      const response = await axios({
        method: 'get',
        url: API_ROUTES.GET_MEDIA + "?subcategory=" + subCategoryId,
        headers: {
          'authkey': APP_PARAMS.ADMIN_KEY,
          Authorization: `Bearer ${getTokenFromLocalStorage()}`
        }
      });
      
      setSubCategoryMedia(response.data)
    }
    catch (err) {
      if (err.response)
        message.error(err.response.data.message);
      else
        message.error(err.message);

    }
    finally {

    }
  }

  const handleSubCategoryMediaDelete = async (subCategoryMediaId) => {
  
    try {
      const response = await axios({
        method: 'Delete',
        url: API_ROUTES.GET_MEDIA + "/" + subCategoryMediaId,
        headers: {
          'authkey': APP_PARAMS.ADMIN_KEY,
          'Authorization': `Bearer ${getTokenFromLocalStorage()}`,
          'Content-Type': 'application/json',
        },

      });

      getSubCategoryMedia(id)
    }
    catch (err) {
      if (err.response)
        message.error(err.response.data.message);
      else
        message.error(err.message);

    }
    finally {

    }
  }

  const onFinishSubCategoryMedia = async (values) => {
    try {
      console.log(values.file[0].originFileObj);
      
      const response = await axios({
        method: 'post',
        url: API_ROUTES.GET_MEDIA,
        headers: {
          'authkey': APP_PARAMS.ADMIN_KEY,
          'Authorization': `Bearer ${getTokenFromLocalStorage()}`,
          "Content-Type": "multipart/form-data",
        },
        data: {
          "subCategory": id,
          "file": values.file[0].originFileObj
        }
      });
      getSubCategoryMedia(id)


    }
    catch (err) {
      if (err.response)
        message.error(err.response.data.message);
      else
        message.error(err.message);

    }
    finally {

    }
  };

  const renderSubCategoryMedia = () => {
    return (
      <>
        <Form

          layout="vertical"
          onFinish={onFinishSubCategoryMedia}
          autoComplete="off"
        >
         
         <Form.Item label="Upload" valuePropName="fileList" getValueFromEvent={normFile} name="file">
          <Upload  listType="picture-card" maxCount={1}>
            <button
              style={{
                border: 0,
                background: 'none',
              }}
              type="button"
            >
              <PlusOutlined />
              <div
                style={{
                  marginTop: 8,
                }}
              >
                Upload
              </div>
            </button>
          </Upload>
        </Form.Item>
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                Kaydet
              </Button>

            </Space>
          </Form.Item>
        </Form>
        <Table columns={columnsSubCategoryMedia} dataSource={subCategoryMedia} />
      </>

    )
  }

  const columnsSubCategoryMedia = [
    {
      title: 'Alt Kategori Görseli',
      dataIndex: 'url',
      key: 'url',
      render: (_, record) => <Image
      width={200}
      src={API_ROUTES.API_IMAGE+"/"+ record.url}
    />
    },
    {
      title: 'Sil',
      dataIndex: 'operation',
      render: (_, record) =>
        subCategoryMedia.length >= 1 ? (
          <Popconfirm title="Silmek istediğinize emin misiniz?" onConfirm={() => handleSubCategoryMediaDelete(record._id)}>
            <a>Delete</a>
          </Popconfirm>
        ) : null,
    },
  ]

  const columnsSubCategoryMediaMain = [
    {
      title: 'Alt Kategori Görseli',
      dataIndex: 'url',
      key: 'url',
      render: (_, record) => <Image
      width={200}
      src={API_ROUTES.API_IMAGE+"/"+ record.url}
    />
    },
    {
      title: 'Sil',
      dataIndex: 'operation',
      render: (_, record) =>
        subCategoryMedia.length >= 1 ? (
          <Popconfirm title="Silmek istediğinize emin misiniz?" onConfirm={() => handleSubCategoryMediaDelete(record._id)}>
            <a>Delete</a>
          </Popconfirm>
        ) : null,
    }
  ]


  return (
    <>
      <div style={{ margin: "-5px 0px" }} className='flex text-lg font-bold rounded-t-3xl backdrop-blur-xl bg-white/60 justify-between p-6  flex-col lg:flex-row'>
        <div className='py-4 lg:py-0'>{subCategoryName}</div>
        <div className='flex gap-1'>
          <Button onClick={() => { setIsSubCategoryTypeModalOpen(true); }}>Alt Kategori İçin Görsel Ekle</Button>
        </div>

      </div>
      <Row>
        <Col span={8} offset={4}>
        <Table columns={columnsSubCategoryMediaMain} dataSource={subCategoryMedia} style={{"marginTop":"50px"}}/>
        </Col>
      </Row>
      
      {isSubCategoryTypeModalOpen &&
        <Modal title="Alt Kategori" open={isSubCategoryTypeModalOpen} onOk={() => setIsSubCategoryTypeModalOpen(false)} onCancel={() => setIsSubCategoryTypeModalOpen(false)} destroyOnClose={true}>
          {renderSubCategoryMedia()}
        </Modal>
      }

    </>
  )
}

export default AddMedia;