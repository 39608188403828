import React, { useContext, useEffect, useRef, useState } from "react";
import {
  AppstoreAddOutlined,
  UserOutlined,
  TeamOutlined,
  SendOutlined,
} from "@ant-design/icons";

import logo from "../../../img/logo.png";
import { Menu, Dropdown, Button } from "antd";
import { useUser } from "../../../lib/customHooks";
import { Modal } from "antd";

import Manufacturer from "../types/Manufacturer";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../../utils/constants";
import { logOut } from "../../../lib/common";

export const Header = () => {
  const [current, setCurrent] = useState("mail");
  const { user, authenticated } = useUser();
  const navigate = useNavigate();
  const onClick = (e) => {
    setCurrent(e.key);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const goToDashboard = () => {
    navigate(APP_ROUTES.CLASSES);
  };

  const goToExam = () => {
    navigate(APP_ROUTES.EXAM);
  };
  const goToEmployee = () => {
    navigate(APP_ROUTES.EMPLOYEE);
  };
  const items = [
    {
      label: "Sınıf İşlemleri",
      key: "classes",
      onClick: goToDashboard,
      icon: <AppstoreAddOutlined />,
    },
    {
      label: "Sınav İşlemleri",
      key: "exam",
      onClick: goToExam,
      icon: <AppstoreAddOutlined />,
    },
  
  ];

  const renderModal = () => {
    if (current === "1") {
      return (
        <Modal
          title="İşletme Tipleri"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
         
        </Modal>
      );
    } else if (current === "2") {
      return (
        <Modal
          title="Operasyon Tipleri"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
     
        </Modal>
      );
    } else if (current === "4") {
      return (
        <Modal
          title="Hava Aracı Sınıfları"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
         
        </Modal>
      );
    } else if (current === "5") {
      return (
        <Modal
          title="Hava Aracı Üreticileri"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Manufacturer />
        </Modal>
      );
    } else return;
  };
  const exit = () => {
    logOut();
    navigate(APP_ROUTES.SIGN_IN);
  };

  return (
    <>
      <div className="container mx-auto">
        <div className="flex p-3 justify-end items-center">
          <div className="flex">
            Hoşgeldin{" "}
            {user && <div className="profile mx-1 font-bold">{user.name}.</div>}
          </div>
          <div style={{ margin: 5 }}>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key="1">Bilgilerini Düzenle</Menu.Item>
                  <Menu.Item key="2" onClick={() => navigate(APP_ROUTES.USERS)}>
                    Kullanıcılar
                  </Menu.Item>
                  <Menu.Item key="3" onClick={() => exit()}>
                    Güvenli Çıkış
                  </Menu.Item>
                </Menu>
              }
            >
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <Button
                  type="primary"
                  style={{ color: "#fff" }}
                  shape="circle"
                  icon={<UserOutlined />}
                />
              </a>
            </Dropdown>
          </div>
        </div>
      </div>
      <div className="container mx-auto">
        <div className=" p-3 flex justify-between items-center">
          <div className="logo">
            <a href="/">
              <img width={50} height={50} src={logo} />
            </a>
          </div>
          <div className="flex-1">
            {" "}
           {" "}
          </div>
        </div>
      </div>

      {renderModal()}
    </>
  );
};
export default Header;
