import { API_ROUTES, APP_PARAMS } from '../utils/constants';
import axios from 'axios';
export function storeTokenInLocalStorage(token) {
  localStorage.setItem('token', token);
}

export function getTokenFromLocalStorage() {
  return localStorage.getItem('token');
}

export function logOut() {
   localStorage.removeItem('token');
}

export async function getAuthenticatedUser() {
  const defaultReturnObject = { authenticated: false, user: null };
  try {
    const token = getTokenFromLocalStorage();
    if (!token) {
      return defaultReturnObject;
    }
    const response = await axios({
      method: 'GET',
      url: API_ROUTES.GET_USER,
      headers: {
        'mobilekey': APP_PARAMS.MOBILE_KEY,
        Authorization: `Bearer ${token}`
      }
    });
    const { authenticated = false } = response.data;

    return { authenticated: true, user: response.data }//authenticated ? response.data : false;
  }
  catch (err) {
    //console.log('getAuthenticatedUser, Something Went Wrong', err);
    return defaultReturnObject;
  }
}

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
  

export const upLoadFile = async (images) => {
  try {
    var frmData = new FormData();
    images.forEach(image => {
      frmData.append('photos', image)
    });
    const response = await axios({
      method: 'POST',
      url: API_ROUTES.ADD_PHOTOS,
      headers: {
        'Authorization': `Bearer ${getTokenFromLocalStorage()}`,
        "Content-Type": "multipart/form-data"
      },
      data: frmData
    });
    return response;
  }
  catch (err) {
    return err;
  }
  finally {

  }
}

export const upLoadDocument = async (document) => {
  try {
    var frmData = new FormData();
    
      frmData.append('document', document)
    
    const response = await axios({
      method: 'POST',
      url: API_ROUTES.ADD_DOCUMENTS,
      headers: {
        'Authorization': `Bearer ${getTokenFromLocalStorage()}`,
        "Content-Type": "multipart/form-data"
      },
      data: frmData
    });
     return response;
  }
  catch (err) {
     return err;
  }
  finally {

  }
}