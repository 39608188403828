import React, { useState } from 'react';
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import { Menu } from 'antd';

const items = [
  

  {
    label: 'İşletmeler',
    key: 'SubMenu',
    icon: <AppstoreOutlined />,
    children: [
      {
        type: 'group',
        children: [
          {
            label: 'Hava Yolu İşletmeleri',
            key: 'setting:1',
          },
          {
            label: 'Hava Taksi İşletmeleri',
            key: 'setting:2',
          },
          {
            label: 'Genel Havacılık İşletmeleri',
            key: 'setting:3',
          },
        ],
      },
   
    ],
  },
  {
    label: 'Hava Araçları',
    key: 'app',
    icon: <AppstoreOutlined />,
  },
  {
    label: 'Sorumlu Personeller',
    key: 'pers',
    icon: <AppstoreOutlined />,
  },

];
export const Header = () => {
  const [current, setCurrent] = useState('mail');
  const onClick = (e) => {
    console.log('click ', e);
    setCurrent(e.key);
  };
  return (
    <div className='h-48 relative'>
      <nav className='backdrop-blur bg-white/50 lg:border-b lg:border-slate-900/10 sticky top-0 z-40 w-full flex-none transition-colors duration-500 lg:z-50  supports-backdrop-blur:bg-white/60'>
      <div className="container mx-auto ">
        <div className="p-2 flex justify-between items-center">
          <div className="logo">
         
          </div>
          <div className='flex justify-center flex-1'> <Menu className="flex justify-end font-semibold" onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} /> </div>
       
        <div>Logout</div>
      </div> </div> 

     

      </nav>
      
    </div>
  )
}

