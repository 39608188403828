import React, { useEffect, useState } from 'react';
import { useParams,useNavigate, useLocation } from "react-router-dom";
import { message, Table, Popconfirm, Modal, Button, Input, Form, Space, Select } from 'antd';

import { API_ROUTES, APP_PARAMS, APP_ROUTES } from '../../utils/constants';
import axios from 'axios';
import { useUser } from '../../lib/customHooks';
import { getTokenFromLocalStorage } from '../../lib/common';
import { Row, Col } from 'antd/es';

const { TextArea } = Input;

const AddText = () => {
  const { user, authenticated } = useUser();
  const [isSubCategoryTypeModalOpen, setIsSubCategoryTypeModalOpen] = useState(false);
  const [subCategoryText, setSubCategoryText] = useState([])
  const [subCategoryName,setSubCategoryName] = useState();
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  
  useEffect(() => {
    if (authenticated) {
      setSubCategoryName(location.state.subCategoryName);
      getSubCategoryText(id);
    }
  }, [authenticated])

  const getSubCategoryText = async (subCategoryId) => {
   
    try {
      const response = await axios({
        method: 'get',
        url: API_ROUTES.GET_TEXT + "?id=" + subCategoryId,
        headers: {
          'authkey': APP_PARAMS.ADMIN_KEY,
          Authorization: `Bearer ${getTokenFromLocalStorage()}`
        }
      });
     
      //if (response.data.length > 0) {
        setSubCategoryText(response.data)
      //}
     

    }
    catch (err) {
      if (err.response)
        message.error(err.response.data.message);
      else
        message.error(err.message);

    }
    finally {

    }
  }

  const handleSubCategoryTextDelete = async (subCategoryTextId) => {
  
    try {
      const response = await axios({
        method: 'Delete',
        url: API_ROUTES.GET_TEXT + "/" + subCategoryTextId,
        headers: {
          'authkey': APP_PARAMS.ADMIN_KEY,
          'Authorization': `Bearer ${getTokenFromLocalStorage()}`,
          'Content-Type': 'application/json',
        },

      });

      getSubCategoryText(id)
    }
    catch (err) {
      if (err.response)
        message.error(err.response.data.message);
      else
        message.error(err.message);

    }
    finally {

    }
  }

  const onFinishSubCategoryText = async (values) => {
    try {
      const response = await axios({
        method: 'post',
        url: API_ROUTES.GET_TEXT,
        headers: {
          'authkey': APP_PARAMS.ADMIN_KEY,
          'Authorization': `Bearer ${getTokenFromLocalStorage()}`,
          'Content-Type': 'application/json',
        },
        data: {
          "subCategory": id,
          "text": values.subCategoryTextName
        }
      });
      getSubCategoryText(id)


    }
    catch (err) {
      if (err.response)
        message.error(err.response.data.message);
      else
        message.error(err.message);

    }
    finally {

    }
  };

  const renderSubCategoryText = () => {
    return (
      <>
        <Form

          layout="vertical"
          onFinish={onFinishSubCategoryText}
          autoComplete="off"
        >
         
          <Form.Item
            name="subCategoryTextName"
            label="Alt Kategori Text İsmi"
            rules={[
              {
                required: true,
              }
            ]}
          >
            <TextArea placeholder="Alt kategori için text giriniz" />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                Kaydet
              </Button>

            </Space>
          </Form.Item>
        </Form>
        <Table columns={columnsSubCategoryText} dataSource={subCategoryText} />
      </>

    )
  }

  const columnsSubCategoryText = [
    {
      title: 'Alt Kategori Metin',
      dataIndex: 'text',
      key: 'text',
    },
    {
      title: 'Sil',
      dataIndex: 'operation',
      render: (_, record) =>
        subCategoryText.length >= 1 ? (
          <Popconfirm title="Silmek istediğinize emin misiniz?" onConfirm={() => handleSubCategoryTextDelete(record._id)}>
            <a>Delete</a>
          </Popconfirm>
        ) : null,
    },
  ]

  const columnsSubCategoryTextMain = [
    {
      title: 'Alt Kategori Metni',
      dataIndex: 'text',
      key: 'text',
    },
    {
      title: 'Sil',
      dataIndex: 'operation',
      render: (_, record) =>
        subCategoryText.length >= 1 ? (
          <Popconfirm title="Silmek istediğinize emin misiniz?" onConfirm={() => handleSubCategoryTextDelete(record._id)}>
            <a>Delete</a>
          </Popconfirm>
        ) : null,
    }
  ]


  return (
    <>
      <div style={{ margin: "-5px 0px" }} className='flex text-lg font-bold rounded-t-3xl backdrop-blur-xl bg-white/60 justify-between p-6  flex-col lg:flex-row'>
        <div className='py-4 lg:py-0'>{subCategoryName}</div>
        <div className='flex gap-1'>
          <Button onClick={() => { setIsSubCategoryTypeModalOpen(true); }}>Alt Kategori İçin Metin Ekle</Button>
        </div>

      </div>
      <Row>
        <Col span={8} offset={4}>
        <Table columns={columnsSubCategoryTextMain} dataSource={subCategoryText} style={{"marginTop":"50px"}}/>
        </Col>
      </Row>
      
      {isSubCategoryTypeModalOpen &&
        <Modal title="Alt Kategori" open={isSubCategoryTypeModalOpen} onOk={() => setIsSubCategoryTypeModalOpen(false)} onCancel={() => setIsSubCategoryTypeModalOpen(false)} destroyOnClose={true}>
          {renderSubCategoryText()}
        </Modal>
      }

    </>
  )
}

export default AddText;